/**
 * Sets up breakpoint and resize listeners which are emitted as events on $window.
 *
 * Events:
 *   breakpointChange.Site  Sent when the window size changes between any of the breakpoints.
 *   throttledResize.Site   Sent when the window is resized, but throttled.
 *
 * @since 0.1.0
 */

import {$window} from './references.js';

// console.log('Site:responsive');

// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// These are not ranges, the is<Size> methods only return whether or not the window is above/below the breakpoint
const breakpoints = {
  small : {
    width      : 570,
    mediaMatch : null,
  },
  medium : {
    width      : 910,
    mediaMatch : null,
  },
  large : {
    width      : 1200,
    mediaMatch : null,
  },
};

// Setup throttle resize listener
$window.on('resize orientationchange', jQuery.throttle(250, onResize));

// Setup listeners for when the browser width passes the breakpoints
Object.entries(breakpoints).forEach(([key, value]) => {
  const mediaMatch = window.matchMedia('only screen and (max-width: ' + (value.width - 1) + 'px)');

  mediaMatch.addListener(onMatchMediaChange);
  mediaMatch.breakpointName = key;

  breakpoints[key].mediaMatch = mediaMatch;
});


// Use dc-srcload to set adaptive content images
// e.g., <div class="bg-src"
//        data-src      = "default.jpg"
//        data-src-640  = "gte-640.jpg"
//        data-src-1024 = "gte-1024.jpg">
jQuery('[data-src]').srcload();

// Use dc-srcload to set adaptive background images.
jQuery('.bg-src').each(function (index, element) {
  element.style.backgroundImage = 'url("' + jQuery(element).bestsrc() + '")';
});

$window.on('breakpointChange.Site', update);
update();


/**
* Fired when the window is resized, throttled.
*
* @since 0.1.0
*/
function onResize() {
  // console.log('Site:onResize');

  $window.trigger('throttledResize.Site');
}


/**
 * Called whenever the mobileMatchMedia condition changes state.
 *
 * Fast and superior to watching resize and comparing window width to a breakpoint.
 * Sends an event when the state changes so other scripts can easily listen for mobile/desktop changes.
 * Adds the custom event properties:
 *   breakpointName string Name of the breakpoint that was crossed.
 *   isBelow        bool   Whether the window size is below the breakpoint.
 *
 * @since 0.1.0
 *
 * @param  object event Event.
 */
function onMatchMediaChange(event) {
  // console.log('Site:responsive:onMatchMediaChange');
  // console.log('event', event);
  // console.log(this);
  // console.log(isBelowSmall(), isBelowMedium(), isBelowLarge(), event.target.breakpointName, isBelow(event.target.breakpointName));

  // The breakpointName property is located in a different position in IE vs everyone else
  let breakpointName = '';

  if ('breakpointName' in event) {
    breakpointName = event.breakpointName;
  } else {
    breakpointName = event.target.breakpointName;
  }

  $window.trigger({
    type           : 'breakpointChange.Site',
    breakpointName : breakpointName,
    isBelow        : isBelow(breakpointName),
  });
}


/**
 * Updates the site so the elements best fit the current screen width.
 *
 * Called when the breakpoints are crossed in either direction.
 *
 * @since 0.1.0
 *
 * @param object event Event.
 */
function update(event) {
  // console.log('Site:responsive:update');
  // console.log('event', event);

  if (isBelowSmall()) {
    // Mobile
    // $footerSearchInput.each(function () {
    //   var $this = jQuery(this);
    //   $this.attr('placeholder', $this.data('mobile-placeholder'));
    // });
  } else {
    // Desktop
    // $footerSearchInput.each(function () {
    //   var $this = jQuery(this);
    //   $this.attr('placeholder', $this.data('placeholder'));
    // });
  }
}


/**
 * Returns true if the screen is less than the specified breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 *
 * @param string size Breakpoint name.
 */
function isBelow(size) {
  // console.log('Site:response:is');

  if (!breakpoints[size]) {
    console.error('Unknown breakpoint', size);

    return false;
  }

  return breakpoints[size].mediaMatch.matches;
}


/**
 * Returns whether the screen is less than the 'small' breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 */
export function isBelowSmall() {
  // console.log('Site:response:isSmall');

  return breakpoints.small.mediaMatch.matches;
}


/**
 * Returns whether the screen is less than the 'medium' breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 */
export function isBelowMedium() {
  // console.log('Site:response:isSmall');

  return breakpoints.medium.mediaMatch.matches;
}


/**
 * Returns whether the screen is less than the 'large' breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 */
export function isBelowLarge() {
  // console.log('Site:response:isSmall');

  return breakpoints.large.mediaMatch.matches;
}
