/**
 * Methods for working with and detecting the admin bar.
 *
 * Events:
 *   adminBarVisible.Site            Sent when the admin bar becomes visible.
 *   adminBarChange.Site             Sent when the admin bar changes heights or position.
 *
 * @since  0.1.0
 */

import * as references from './references.js';

// console.log('Site:adminBar');

let $adminBar                = null
  , isPresent                = references.$body.hasClass('admin-bar')
  , height                   = 0
  , heightChangeMatchMedia   = null
  , positionChangeMatchMedia = null
;

export let isFixed = false;

if (isPresent) {
  heightChangeMatchMedia = window.matchMedia('only screen and (min-width: 783px)');
  positionChangeMatchMedia = window.matchMedia('only screen and (min-width: 600px)');

  heightChangeMatchMedia.addListener(onHeightMatchMediaChange);
  positionChangeMatchMedia.addListener(onPositionMatchMediaChange);
}

watchForWhenVisible();


/**
 * If the admin bar is present on the page, watches for it become visible, then fires an event
 * 'adminBarVisible.Site' when it can be accessed.
 *
 * @since 0.1.0
 */
function watchForWhenVisible() {
  // console.log('Site:adminBar:watchForWhenVisible');

  if (!isPresent) {
    return;
  }

  let triesLeft = 100;

  const adminBarCheckInterval = setInterval(() => {
    // console.log('checking', jQuery('#wpadminbar').is(':visible'));

    // Can't use $adminBar here, jQuery doesn't pick up when it's visible
    if (jQuery('#wpadminbar').is(':visible')) {
      // console.log('Now exists');
      clearInterval(adminBarCheckInterval);

      $adminBar = jQuery('#wpadminbar');
      isPresent = true;

      updateIsFixed();
      references.$body.trigger('adminBarVisible.Site');
    } else {
      triesLeft--;

      if (triesLeft === 0) {
        // console.log('Limit reached');
        clearInterval(adminBarCheckInterval);
      }
    }
  }, 50);
}


/**
 * Called whenever the heightChangeMatchMedia condition changes, which is when the admin bar changes height.
 *
 * @since 0.1.0
 *
 * @param  object event Event.
 */
function onHeightMatchMediaChange(event) {
  // console.log('Site:adminBar:onHeightMatchMediaChange');

  triggerAdminBarChangeEvent();
}


/**
 * Called whenever the positionChangeMatchMedia condition changes, which is when the admin bar position changes.
 *
 * @since 0.1.0
 *
 * @param  object event Event.
 */
function onPositionMatchMediaChange(event) {
  // console.log('Site:adminBar:onPositionMatchMediaChange');

  updateIsFixed();
  triggerAdminBarChangeEvent();
}


/**
 * Called whenever some dimension on the admin bar changes.
 *
 * Sends an event.
 * Adds the custom event properties:
 *   height  int  Height of admin bar.
 *   isFixed bool Whether or not the admin bar is fixed at the top of the page or allowed to scroll.
 *
 * @since 0.1.0
 */
function triggerAdminBarChangeEvent(event) {
  // console.log('Site:adminBar:triggerAdminBarChangeEvent');

  updateIsFixed();
  updateHeight();

  references.$body.trigger({
    type    : 'adminBarChange.Site',
    height  : height,
    isFixed : isFixed,
  });
}


/**
 * Returns the height of the WordPress Admin Bar.
 *
 * @since 0.1.0
 *
 * @return int Admin bar height. 0 if not present.
 */
export function getHeight() {
  if (!isPresent || !$adminBar) {
    return 0;
  }

  return $adminBar.height();
}


function updateHeight() {
  height = getHeight();
}


function updateIsFixed() {
  isFixed = $adminBar.css('position') === 'fixed';
}
