// React and ReactDOM are loaded globally
// These are always available via the ProvidePlugin webpack plugin
// import React from 'react';
// import ReactDOM from 'react-dom';
// import Redux from 'redux';
// import ReactRedux from 'react-redux';
// end
import FilterableBrands from './components/FilterableBrands';


const tagMap = {
  FilterableBrands,
};

// Load top-level React components using Angular like tags
for (const [tagName, component] of Object.entries(tagMap)) {
  const tagList = document.getElementsByTagName(tagName);

  // console.log('tagList', tagList);

  for (const element of tagList) {
    const props = {};

    // Convert all data- attributes into props
    for (const [key, value] of Object.entries(element.dataset)) {
      try {
        props[key] = JSON.parse(value);
      } catch (e) {
        console.log('Failed to parse:', value);
        props[key] = value;
      }
    }

    ReactDOM.render(React.createElement(component, props), element);
  }
}
