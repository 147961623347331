// console.log('Site:tables');

import {getQueryVars} from './utils.js';

const $filterTriggers = jQuery('[data-table-filter]');

const table = jQuery('table[data-sortable]').DataTable({
  stateSave  : true,
  scrollX    : true,
  lengthMenu : [
    [20, 50, 100, -1],
    [20, 50, 100, 'All'],
  ],
  columnDefs : [
    {
      targets   : [1],
      orderable : false,
    },
  ],
  language : {
    info       : 'Showing _START_ to _END_ of _TOTAL_ kegs',
    lengthMenu : 'Show _MENU_ kegs',
    search     : 'Search',
  },
});

$filterTriggers.click((e) => {
  e.preventDefault();

  const term = e.target.getAttribute('data-table-filter');
  const col = e.target.getAttribute('data-table-column');

  const query = {};

  query[col] = term;

  if (term === 'all') {
    filterTable();
  } else {
    filterTable(query);
  }
});

function handleQueryParams() {
  const query = getQueryVars(true);

  filterTable(query);
}

handleQueryParams();

function filterTable(query) {
  $filterTriggers.removeClass('active');

  // Reset the entire filter
  if (!query) {
    jQuery('[data-table-filter="all"]').addClass('active');

    table
      .columns().search('')
      .draw();

    return false;
  }

  for (const item in query) {
    if (item) {
      $filterTriggers.each((i, el) => {
        if (
          el.getAttribute('data-table-filter') === query[item] &&
          el.getAttribute('data-table-column') === item
        ) {
          el.classList.add('active');
        }
      });

      table
        .column('#col-' + item)
        .search(query[item])
        .draw();
    }
  }
}
