/**
 * Site analytics.
 *
 * @since 0.1.0
 */

// console.log('Site:analytics');


// Set out queued analytics events
if (window.queuedAnaltyics && Array.isArray(window.queuedAnaltyics)) {
  for (const event of window.queuedAnaltyics) {
    const {
      name = '',
      data = {},
    } = event;

    if (name) {
      track(name, data);
    }
  }
}


/**
 * Tracks an event, pushing it to data collection services like GTM.
 *
 * @since 0.1.0
 *
 * @param  string name Event name.
 * @param  object data  Event data.
 */
export function track(name, data) {
  // console.log('analytics:track', name, data);

  // For now, only GTM is used
  const trackingData = {
    event : name,
  };

  if (data) {
    trackingData.data = data;
  }

  // console.log(name, trackingData);

  window.dataLayer = window.dataLayer || [];

  // Could push to Segment or other trackers later
  window.dataLayer.push(trackingData);
}


// API
window.track = track;
