/**
 * Front Page JS
 *
 * @since 0.1.0
 */

import {$body} from './references.js';

// console.log('Site:front-page');


if ($body.hasClass('page-front-page')) {
  const video = document.querySelector('video');

  if (video) {
    videoInView(video);
  }

  tabs();
}


// Stop playing video when not in view
function videoInView(video) {
  // console.log('FrontPage:videoInView');

  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    const observer = new IntersectionObserver((entries) => {
      // console.log(entries[0]);

      if (entries[0].intersectionRatio === 0) {
        // console.log('stop video');
        video.pause();
      } else {
        // console.log('play video');
        if (video.paused) {
          video.play();
        }
      }
    });

    observer.observe(video);
  }
}


// Resize flickity images when initialized from tabs
function tabs() {
  // console.log('FrontPage:tabs');

  document.addEventListener('tabby', function (event) {
    // const content = event.detail.content;
    const flickity = jQuery(event.detail.content).find('.flickity-enabled').data('flickity');

    if (flickity) {
      flickity.resize();
    }
  }, false);
}
