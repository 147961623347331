/* eslint-disable jsx-a11y/no-onchange */
// https://webaim.org/discussion/mail_thread?thread=8036

import PropTypes from 'prop-types';
// import classnames from 'classnames';
import update from 'immutability-helper';

import Pager from '../Pager';

const ITEM_PAGE_SIZE = 20;

const FILTERS = {
  brandType : {
    options : [
      {
        id   : 'beer',
        name : 'Beer/Cider',
      },
      {
        id   : 'wine',
        name : 'Wine',
      },
      {
        id   : 'rtd_cocktails',
        name : 'RTD Cocktails',
      },
      {
        id   : 'other',
        name : 'Other',
      },
    ],
  },
};


export default class FilterableBrands extends React.Component {
  static propTypes = {
    data : PropTypes.object.isRequired,
  }

  ignoreNextHashChange = false;


  constructor(props) {
    super(props);

    const filters = this.updateFiltersWithParams(this.getHashParams());

    // console.log('constructor:filters', filters);

    this.handleHashChangeFn = this.handleHashChange.bind(this);

    this.state = {
      items          : [],
      currentPage    : 1,
      currentFilters : filters,
      currentSearch  : '',
    };
  }


  componentDidMount() {
    // console.log('FilterableBrands:componentDidMount');
    // console.log('this.state.filterViewMode', this.state.filterViewMode);

    window.addEventListener('hashchange', this.handleHashChangeFn);
  }


  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChangeFn);
  }


  componentDidUpdate(prevProps, prevState) {
    // console.log('FilterableBrands:componentDidUpdate');
    // console.log(prevState.filterViewMode, this.state.filterViewMode);
  }


  // Called when the dropdown button is click
  handleFilterDropdownClick(event, filterKey) {
    // console.log('FilterableBrands:handleFilterDropdownClick');

    this.toggleFilterDropdown(filterKey);
  }


  handleSearch(event) {
    // console.log('FilterableBrands:handleSearch');
    // console.log(event.currentTarget.value);

    this.setState({
      currentSearch : event.currentTarget.value.toLowerCase(),
    });
  }


  setFilter(key, value) {
    this.setState((prevState) => update(
      this.state,
      {
        currentFilters : {
          $apply(currentFilters) {
            // console.log('key', key);
            prevState.currentFilters[key] = value;

            return currentFilters;
          },
        },
      },
    ));
  }


  handleSelectFilterChange(event, key) {
    // console.log('FilterableBrands:handleFilterChange');
    // console.log(key);
    // console.log(event.currentTarget.value);
    event.persist();

    const filter = {};

    let value = '';

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    // console.log(event.target.value);

    this.setFilter(key, value);

    filter[key] = value;

    this.setHashParams(filter);

    this.setState({
      currentPage : 1,
    });
  }


  updateFiltersWithParams(params) {
    // console.log('FilterableListings:updateFiltersWithParams');

    // console.log('updateFiltersWithParams:params', params);

    const filters = {};

    for (const [paramKey, paramValue] of Object.entries(params)) {
      if (paramKey in FILTERS) {
        filters[paramKey] = paramValue;
      }
    }

    // console.log('updateFiltersWithParams:filters', filters);

    return filters;
  }


  handleHashChange() {
    // console.log('FilterableBrands:handleHashChange');
    // console.log('this.state', this.state);
    // console.log('this.getHashParams()', this.getHashParams());

    if (this.ignoreNextHashChange) {
      // console.log('Ignored');
      this.ignoreNextHashChange = false;

      return;
    }

    // console.log('Update filters');

    const params = this.getHashParams()
        , that = this
    ;

    this.setState(
      (prevState) => update(
        this.state,
        {
          currentFilters : {
            $apply(currentFilters) {
              // console.log('handleHashChange:setState', that.updateFiltersWithParams(params));

              return that.updateFiltersWithParams(params);
            },
          },
        },
      ),
    );
  }


  getHashParams() {
    // console.log('getHashParams', location.hash.replace('#?', '?'));

    const params = {};

    for (const [key, value] of new URLSearchParams(location.hash.replace('#?', '?')).entries()) {
      // console.log('value', key, value);

      params[key] = value;
    }

    return params;
  }


  // Changes the URL hash to match the selected filters.
  // Input should be from getSelectedFilters()
  setHashParams(filters) {
    // console.log('setHashParams', filters);

    const params = {};

    for (const [filterKey, filterValue] of Object.entries(filters)) {
      // console.log('filterKey', filterKey);
      // console.log('filterValue', filterValue);

      // if (filterKey === 'count' || filterKey === 'page') {
      //   continue;
      // }

      // const filter = FILTER_URL_MAPPING.find((filter) => filter.filterKey === filterKey || filter.paramKey === filterKey);

      if (!(filterKey in FILTERS) || !filterValue) {
        continue;
      }

      // // console.log('filter', filter);

      params[filterKey] = Array.isArray(filterValue) ? filterValue.join() : filterValue;
    }

    // console.log('params', params);

    this.ignoreNextHashChange = true;

    const hash = new URLSearchParams(params).toString();

    // console.log(hash);

    // The ? is also added because this prevents the page from jumping to the top if no other values are set
    window.location.hash = '?' + (hash ? hash : '');
  }


  navigateToPage(event) {
    this.setState({
      currentPage : Number(event.currentTarget.getAttribute('data-rel')),
    });
  }


  /* eslint-disable complexity */
  render() {
    // console.log('FilterableBrands:render');
    // console.log('FilterableBrands', 'this.props', this.props);
    // console.log('this.state', this.state);
    // console.log('this.currentFilters', this.state.currentFilters);

    // console.log('render:state', this.state.currentFilters);

    const cards = [];

    // console.log(this.props.data.items);

    let filteredPosts = this.props.data.items;

    // console.log(this.state.currentFilters);
    // this.state.currentFilters.forEach((filter) => {
    //   console.log('foobar');
    // });

    filteredPosts = filteredPosts.filter((item) => {
      let valid = Object.keys(this.state.currentFilters).reduce((accumulator, key) => {
        // console.log('key', key);
        // console.log('value', this.state.currentFilters[key]);

        if (!accumulator) {
          return accumulator;
        }

        // console.log('key', key);
        // console.log('item[key]', item[key]);
        // console.log('filter', this.state.currentFilters[key]);

        return item[key] === this.state.currentFilters[key] || !this.state.currentFilters[key];
      }, true);

      if (valid && this.state.currentSearch) {
        valid = item.title.toLowerCase().search(this.state.currentSearch) !== -1 || item.brandLocation.toLowerCase().search(this.state.currentSearch) !== -1;
      }

      // console.log('item', item);
      // console.log('valid', valid);

      return valid;
    });

    const totalPages = Math.ceil(filteredPosts.length / ITEM_PAGE_SIZE);

    // Limit the filteredPosts to the current page
    filteredPosts = filteredPosts.filter((item, i) =>
      i > this.state.currentPage * ITEM_PAGE_SIZE - ITEM_PAGE_SIZE - 1 && i <= this.state.currentPage * ITEM_PAGE_SIZE - 1,
    );

    const featuredContentHTML = {
      __html : this.props.data.featuredContent,
    };

    filteredPosts.forEach((card) => {
      if (!card.id || !card.type) {
        console.log('Card missing required values', card);

        return;
      }

      const cardInner = (
        <div className="card-inner">
          <h2>{card.title}</h2>
          <div className="logo">
            <img src={card.image.url} alt={card.image.alt} />
          </div>
          <div className="info">
            {
              card.brandTypeFormatted &&
              <span className="type">{card.brandTypeFormatted}</span>
            }
            {
              card.brandLocation &&
              <span> {String.fromCharCode(183)} <span className="location">{card.brandLocation}</span></span>
            }
          </div>
        </div>
      );

      let cardElem = (
        <article className="card" key={`${card.type}-${card.id}`}>
          {cardInner}
        </article>
      );

      if (card.url) {
        const attrs = {
          target : '_blank',
          rel    : 'noopener noreferrer',
        };

        cardElem = (
          <article className="card has-link" key={`${card.type}-${card.id}`}>
            <a {...!card.internal ? attrs : ''} href={card.url}>
              {cardInner}
            </a>
          </article>
        );
      }

      cards.push(
        cardElem,
      );
    });

    const typeOptions = [];

    typeOptions.push(
      <option key="filter-type-all" value="all">All</option>,
    );

    FILTERS.brandType.options.forEach((item) => {
      typeOptions.push(
        <option key={`filter-type-${item.id}`} value={item.id}>{item.name}</option>,
      );
    });

    // console.log('typeOptions', typeOptions);

    // console.log(cards.length);

    return (
      <div>
        <section className="brand-archive-preheader">
          <section className="brand-filter" role="search">
            <label htmlFor="type-filter">Brand Type:</label>
            <div className="filter">
              <select value={this.state.currentFilters.brandType} onChange={(event) => this.handleSelectFilterChange(event, 'brandType')} id="type-filter">
                {typeOptions}
              </select>
            </div>
            <div className="search">
              <label className="sr-only" htmlFor="search">Search</label>
              <input id="search" type="text" placeholder="Search" onChange={(event) => this.handleSearch(event)} />
            </div>
          </section>

          {this.props.data.featuredContent && (
            <section
              className="brand-featured-content"
              dangerouslySetInnerHTML={featuredContentHTML}
            ></section>
          )}
        </section>

        {cards.length > 0 &&
          <section className="brands-list">
            {cards}
          </section>
        }

        {cards.length === 0 && (
          <div className="no-results">
            <p>Sorry, no brands found. Try another search.</p>
          </div>
        )}

        {totalPages > 1 && (
          <nav className="pagination">
            <Pager totalPages={totalPages} currentPage={this.state.currentPage} onClick={(event) => this.navigateToPage(event)} />
          </nav>
        )}
      </div>
    );
  }
}
