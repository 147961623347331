/**
 * Front Page JS
 *
 * @since 0.1.0
 */

// import {$body} from './references.js';

// console.log('Site:header');

jQuery('.fancybox-trigger').click((e) => {
  e.preventDefault();

  const href = e.target.getAttribute('href');

  if (href) {
    jQuery.fancybox.open({
      src : href,
    });
  }

});
