/**
 * Primary Nav Js
 *
 * @since 0.1.0
 */

// import {$body} from './references.js';

// console.log('Site:primay-nav');

setupMobileNav();

function setupMobileNav() {
  const menuPrimaryContainer = document.getElementById('menu-primary-container');

  const mobileMenuTrigger = document.getElementById('mobile-menu-trigger');
  const mobileMenuClose = document.getElementById('mobile-menu-close');

  if (mobileMenuTrigger && mobileMenuClose) {
    mobileMenuTrigger.addEventListener('click', (e) => {
      menuPrimaryContainer.classList.add('active');
    });

    mobileMenuClose.addEventListener('click', (e) => {
      menuPrimaryContainer.classList.remove('active');
    });
  }
}
