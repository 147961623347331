/* eslint-disable jsx-a11y/no-onchange */
// https://webaim.org/discussion/mail_thread?thread=8036

import PropTypes from 'prop-types';


export default class Pager extends React.Component {
  static propTypes = {
    currentPage : PropTypes.number.isRequired,
    totalPages  : PropTypes.number.isRequired,
    onClick     : PropTypes.func.isRequired,
  }


  constructor(props) {
    super(props);

    // console.log('props', props);
  }


  /* eslint-disable complexity */
  render() {
    const currentPage = this.props.currentPage;
    const lastPage = this.props.totalPages;
    const delta = 1;
    const range = [];

    for (let i = Math.max(2, (currentPage - delta)); i <= Math.min((lastPage - 1), (currentPage + delta)); i += 1) {
      range.push(i);
    }

    if ((currentPage - delta) > 2) {
      range.unshift(-1); // -1 represents an ellipsis
    }

    if ((currentPage + delta) < (lastPage - 1)) {
      range.push(-1);
    }

    range.unshift(1);

    if (lastPage !== 1) {
      range.push(lastPage);
    }

    return (
      <ul>
        {
          currentPage > 1 &&
          <li><button data-rel={currentPage - 1} onClick={this.props.onClick}>&lt;</button></li>
        }

        {range.map((item, i) => {
          if (item === -1) {
            return (
              <li className="ellipsis" key={`pager-item-${i}`}>...</li>
            );
          }

          return (
            <li key={`pager-item-${i}`}><button className={item === currentPage ? 'active' : ''} data-rel={item} onClick={this.props.onClick}>{item}</button></li>
          );
        })}

        {
          currentPage < lastPage &&
          <li><button data-rel={currentPage + 1} onClick={this.props.onClick}>&gt;</button></li>
        }
      </ul>
    );
  }
}
